import { Link, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { ErrorList } from './errorList'

export interface ErrorMessageProps {
  name?: string | RegExp
  description?: string
}

function UserDetailedError({ description }: ErrorMessageProps) {
  return <Typography style={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>{description}</Typography>
}

function ServiceHubMessage({ name }: ErrorMessageProps) {
  const defaultMessage =
    'This action could not be completed. Please raise an issue for investigation via the '
  const url =
    'https://bet365-dwp.onbmc.com/dwp/rest/share/OJSXG33VOJRWKVDZOBST2Q2BKRAUYT2HL5BUCVCFI5HVEWJGORSW4YLOOREWIPKBI5DUQTCQIZEUCMZVIQ3UCUSGKVMFUU2SIZKVQWSTIE3UUNJGOJSXG33VOJRWKSLEHUYTANRQGITHG33VOJRWKVDZOBST2U2CIUTHA4TPOZUWIZLSKNXXK4TDMVHGC3LFHVJUERI='
  let foundItem
  if (typeof name === 'string' && name.indexOf('Days[') >= 0) {
    const idx = name.split('[')[1].split(']')[0]
    if (name === `Days[${idx}].Hours`) {
      foundItem = ErrorList.find(er => `Days[${idx}].Hours`.match(er.name))
    }
  } else {
    foundItem = ErrorList.find(er => er.name === name)
  }
  return (
    <>
      <Typography style={{ fontSize: '13px' }}>
        {foundItem ? foundItem.message : defaultMessage}
        {!foundItem && (
          <Link href={url} target="_blank">
            Service Hub.
          </Link>
        )}
        {foundItem?.link && foundItem.message && (
          <Link href={url} target="_blank">
            Service Hub.
          </Link>
        )}
      </Typography>
    </>
  )
}

function UserErrorMessage({ name, description }: ErrorMessageProps): ReactElement {
  return (
    <>
      {description ? (
        <UserDetailedError description={description} />
      ) : (
        <ServiceHubMessage name={name} />
      )}
    </>
  )
}

export default UserErrorMessage
