import { EnhancementStub } from '../../models/enhancement'

export function enhancementFilter(
  enhancements: EnhancementStub[],
  enhancementTypes: string[]
): EnhancementStub[] {
  return enhancementTypes.length
    ? enhancements.filter(enhancement => enhancementTypes.includes(enhancement.enhancementType))
    : enhancements
}

export function enhancementsStatsFilter(
  enhancements: EnhancementStub[],
  enhancementTypes: string[]
): EnhancementStub[] {
  return enhancementFilter(
    enhancements.filter(enhancement => !enhancement.excludeFromStats),
    enhancementTypes
  )
}
