/* eslint-disable prefer-arrow-callback */
import {
  GridColDef,
  GridRowClassNameParams,
  GridRowId,
  GridRowParams,
  GridSlotsComponent,
} from '@mui/x-data-grid'
import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Link,
  styled,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { setSelectedDepartment } from '../../redux/reducers/appSettingsReducer'
import {
  setSelectedDepartmentFilter,
  setFilterStates,
  setEnhancementsOnlyToggle,
  setCheckboxCount,
} from '../../redux/reducers/myActionsReducer'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { SelectOption } from '../../services/dashboardService'
import { manualRequestsService } from '../../services/myActionsService'

import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import NoResult from '../../shared/UI/NoResult'

import StatusLabel from '../../shared/UI/StatusLabel'
import TypeLabel from '../../shared/UI/TypeLabel'
import Card from '../../shared/layout/Card'
import { isMobileDown } from '../../theme/deviceChecks'
import { getPalleteTypeByProp } from '../../theme/palette'
import theme from '../../theme/theme'
import { BaseResponse } from '../../types/base-response'
import { getSelectValuesByType, getFilteredDepartmentsDropdownOptions } from '../../utils/app-utils'
import { getLocalDateString } from '../../utils/date-utils'
import UserErrorMessage from '../../utils/errorFilter'
import Alert from '../../shared/UI/Alert/Alert'
import { CallOutSummary } from '../CallOutSummary/CallOutSummary'
import { RowExpansionToggle } from './RowExpansionToggle'
import { operationTypes, OperationType, enhancementTypes } from '../../utils/constants'
import {
  BulkSubmitRequest,
  BulkSubmitResponse,
  RequestParams,
  SubmitStatus,
} from '../../models/enhancement'
import { v2MyActionsService } from '../../services/myActionsServiceV2'
import { ManagerRequests } from '../../types/manager-requests'
import {
  SytledMyActionsGrid,
  approveButtonStyles,
  bulkActionsContainer,
  countChipStyles,
  declineButtonStyles,
} from './StyledMyActionsGrid'
import { resolveToPath } from './pathResolver'
import Modal from '../../shared/UI/Modal'
import ButtonSmall from '../../shared/UI/ButtonSmall'
import { isEnhancement } from '../../utils/SharedMethods/isEnhancement'
import CustomGridFooter from './CustomGridFooter'
import { FlattenedRequest } from '../../types/flattened-requests'
import MyActionsFilter from '../MyActions/MyActionsFilter/MyActionsFilter'
import { CheckboxFilterSettings, SelectionList } from '../MyActions/MyActionsFilter/Types'
import FilterChips from '../MyActions/MyActionsFilter/FilterChips'
import {
  filter,
  getInitialCheckboxSettings,
  getInitialRequestParams,
  getInitialSelectionStates,
} from './myactionsFlitering'
import { getRequestStatusDisplayName } from '../../utils/SharedMethods/getRequestStatusDisplayName'
import { removeTrailingZeroes } from '../../utils/conversion'


function NoRowsOverlay() {
  return <NoResult message="No Actions" showImage />
}
function LoadingOverlay() {
  return <LoadingIndicator show alignItems="center" />
}

function headerText(testId: string, headerName?: string) {
  return (
    <div style={{ fontWeight: 'bold', color: 'black' }} data-testid={testId}>
      {headerName}
    </div>
  )
}

const getSelectedRequestTypes = (checkboxSettings: CheckboxFilterSettings[]) =>
  checkboxSettings.filter(setting => setting.checked)

export function MyActionsGrid() {
  const apiRef = useGridApiRef()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState<number>(10)

  const [rerenderCheckBox, setRerenderCheckBox] = useState<number>(1)
  const [rerenderDataGrid, setRerender] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [doesDisplayInfoMessage, setDoesDisplayInfoMessage] = useState<boolean>(true)
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])
  const mobile = useMediaQuery(isMobileDown())
  const [bulkSumitDeclineRequestIds, setBulkSumitDeclineRequestIds] = useState<number[]>([])
  const [bulkSumitApproveRequestIds, setBulkSumitApproveRequestIds] = useState<number[]>([])
  const [headerChecked, setHeaderChecked] = useState<boolean>(false)
  const [requestsArray, setRequestsArray] = useState<FlattenedRequest[]>([])
  const [comments, setComments] = useState<string>('')
  const [commentsError, setCommentsError] = useState<boolean>(false)

  const { selectedDepartmentFilter } = useSelector((state: RootStore) => state.myActions)

  const checkboxSettings = useSelector(
    (state: RootStore) => state.myActions.filterStates.checkboxSettings,
    shallowEqual
  )
  const requestParams = useSelector(
    (state: RootStore) => state.myActions.filterStates.requestParams,
    shallowEqual
  )
  const selectionStateEmployees = useSelector(
    (state: RootStore) => state.myActions.filterStates.selectionState.employees,
    shallowEqual
  )
  const metaData = useSelector((state: RootStore) => state.myActions.filterStates.metaData)

  interface ModalState {
    message: string
    operation: OperationType
    requestIds: number[]
  }

  const modalState: ModalState = {
    message: '',
    operation: operationTypes.APPROVE,
    requestIds: [],
  }

  const [showModal, setShowModal] = useState(false)
  const modalStateRef = useRef(modalState)

  const { departments, allEmployees, selectedDepartment, allTeams } = useSelector(
    (state: RootStore) => state.appSettings
  )
  const dropdownDepartments = useMemo(
    () => getFilteredDepartmentsDropdownOptions(departments, allEmployees),
    [allEmployees, departments]
  )
  const userDepartment = useSelector<RootStore, SelectOption | null>(
    (state: RootStore) => state.appSettings.loggedInEmployeeDepartment
  )

  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])
  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)
  const { calendarDetailsResponse } = useSelector((state: RootStore) => state.appSettings)
  const requestStatusResult = getSelectValuesByType('RequestStatus')
  const unallocated = 'unallocated'
  const shouldSelectAllOption = useCallback(
    (): boolean =>
      userDepartment?.displayValue.toLowerCase().trim() === unallocated || departments.length > 1,
    [userDepartment?.displayValue, departments.length]
  )

  const selectionLists = useRef<SelectionList>({
    departments: [],
    teams: {},
    employees: [],
    statuses: [{ value: 0, displayValue: 'All' }],
    directReportees: true,
  })

  // track the number of bulk decline requests that are call outs.
  const callOutDeclinedCount = useRef<number>(0)
  // track if single decline request is of type call out
  const hasCallOutDeclined = useRef<boolean>(false)
  // check if user clicked on single or bulk decline
  const declinedTriggeredBy = useRef<string>('')

  const filteredRequests = useMemo(
    () => filter(mobile, checkboxSettings, requestsArray),
    [mobile, requestsArray, checkboxSettings]
  )

  // if all filters have been removed, and we only fetched enhancements so far, we need to fetch more data
  // before we can render the table contents
  const isMissingData =
    getSelectedRequestTypes(checkboxSettings).length === 0 && requestParams.enhancementsOnly
  const showLoadingSpinner = isLoading || isMissingData

  useEffect(() => {
    setHeaderChecked(false)
    setBulkSumitDeclineRequestIds([])
    setBulkSumitApproveRequestIds([])
  }, [filteredRequests])

  useEffect(() => {
    selectionLists.current.directReportees = true
    selectionLists.current.employees = allEmployees
    if (departments) {
      departments.forEach(department => {
        const teams =
          department?.value !== 0
            ? allTeams.filter(team => team.departmentId === department.value)
            : []
        selectionLists.current.teams[department.value] = teams
      })
      selectionLists.current.departments = dropdownDepartments
    }

    if (requestStatusResult.length) {
      selectionLists.current.statuses = requestStatusResult
    }
  }, [
    allTeams,
    departments,
    selectedDepartment?.value,
    selectedDepartmentFilter?.value,
    requestStatusResult,
    allEmployees,
    dropdownDepartments,
  ])

  const fetchRequests = useCallback(async () => {
    setIsLoading(true)
    if (!requestParams || enhancementsSettingOn === undefined) return
    const {
      departmentId,
      departmentTeamId,
      directReportees,
      dateFrom,
      dateTo,
      requestStatusId,
      employeeIds,
    } = requestParams
    const getRequests: Promise<ManagerRequests> = enhancementsSettingOn
      ? v2MyActionsService.getRequests(requestParams)
      : manualRequestsService.getRequests(
          directReportees,
          dateFrom,
          dateTo,
          departmentId,
          departmentTeamId,
          employeeIds,
          requestStatusId
        )
    await getRequests
      .then(deptTeams => {
        let { requests } = deptTeams
        // Temporary workaround for the bug where API is returning extra users
        // in the scenario with secondary approvers
        const selectedEmployeeNames = selectionStateEmployees?.map(emp => emp.employeeName) || []
        if (selectedEmployeeNames.length > 0) {
          requests = requests.filter(
            request => request.displayName && selectedEmployeeNames.includes(request.displayName)
          )
        }
        setRequestsArray(requests)
        dispatch(setCheckboxCount(deptTeams.requests))
        setIsLoading(false)
      })
      .catch(err => {
        const response: BaseResponse = err.response?.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
      })
  }, [enhancementsSettingOn, requestParams, dispatch])

  useEffect(() => {
    if (metaData.initialised) {
      fetchRequests()
    }
  }, [metaData.initialised, fetchRequests])

  useEffect(() => {
    if (requestParams.enhancementsOnly && getSelectedRequestTypes(checkboxSettings).length === 0) {
      // if only enhancements have been fetched so far, and all filter chips have been removed,
      // we need to fetch all request types
      setIsLoading(true)
      dispatch(setEnhancementsOnlyToggle(false))
    }
  }, [requestParams, checkboxSettings, dispatch])

  useEffect(() => {
    const isNotInitialisedAndAllRequiredValuesPresent =
      !metaData.initialised &&
      currentEntitlementPeriodResponse &&
      calendarDetailsResponse &&
      (selectedDepartmentFilter?.value || selectedDepartmentFilter?.value === 0) &&
      enhancementsSettingOn !== undefined

    if (isNotInitialisedAndAllRequiredValuesPresent) {
      const initialRequestParams = getInitialRequestParams(
        calendarDetailsResponse,
        selectedDepartmentFilter,
        currentEntitlementPeriodResponse
      )

      const selectionState = getInitialSelectionStates(
        calendarDetailsResponse,
        selectedDepartmentFilter,
        currentEntitlementPeriodResponse
      )

      if (initialRequestParams && selectionState) {
        dispatch(
          setFilterStates({
            requestParams: initialRequestParams,
            selectionState,
            checkboxSettings: getInitialCheckboxSettings(enhancementsSettingOn),
            requestParamsDefaults: { ...initialRequestParams },
            selectionStateDefault: { ...selectionState },
            metaData: { initialised: true },
          })
        )
      }
    }
  }, [
    currentEntitlementPeriodResponse,
    calendarDetailsResponse,
    selectedDepartmentFilter?.value,
    enhancementsSettingOn,
    requestsArray,
    selectedDepartmentFilter,
    metaData.initialised,
    dispatch,
  ])

  useEffect(
    function initialiseDepartmentSelection() {
      const toBeSelected =
        (shouldSelectAllOption()
          ? dropdownDepartments.find(dep => dep.value === 0)
          : userDepartment) ?? null

      dispatch(setSelectedDepartmentFilter(toBeSelected))
      dispatch(setSelectedDepartment(toBeSelected))
    },
    [departments, dispatch, dropdownDepartments, shouldSelectAllOption, userDepartment]
  )

  const calculateStatusLabel = (row: any) => {
    if (row.isCancellation) return 'Cancellation Requested'
    if (!row.statusDescription) return
    if (row.isQueried) return 'Queried'
    return getRequestStatusDisplayName(row.statusDescription)
  }

  const updateBulkApproveDeclineArrays = (
    isChecked: boolean,
    id: number,
    statusDescription: string,
    requestType: string
  ) => {
    const addToArrays = () => {
      bulkSumitDeclineRequestIds.push(id)
      if (statusDescription === SubmitStatus.PENDING) bulkSumitApproveRequestIds.push(id)
    }

    const removeFromArray = (requestId: number, array: number[]) => {
      const index = array.indexOf(requestId)
      if (index > -1) {
        array.splice(index, 1)
      }
    }

    const updateCallOutCount = (value: number) => {
      if (requestType === 'Call Out') {
        callOutDeclinedCount.current += value
      }
    }

    if (isChecked) {
      addToArrays()
      updateCallOutCount(1)
    } else {
      removeFromArray(id, bulkSumitDeclineRequestIds)
      removeFromArray(id, bulkSumitApproveRequestIds)
      updateCallOutCount(-1)
    }
  }

  const isChecked = (id: number): boolean => bulkSumitDeclineRequestIds.includes(id)
  const canApprove = (status: string, requestType: string) =>
    isEnhancement(requestType) ? SubmitStatus.PENDING === status : false
  const canDecline = (status: string, requestType: string): boolean => {
    const declineStatuses: string[] = [
      SubmitStatus.AMENDED,
      SubmitStatus.APPROVED,
      SubmitStatus.PENDING,
    ]
    return isEnhancement(requestType) ? declineStatuses.includes(status) : false
  }

  const handleSuccess = async (response: BulkSubmitResponse, approvedDeclined: string) => {
    setBulkSumitDeclineRequestIds([])
    setBulkSumitApproveRequestIds([])
    if (checkboxSettings) await fetchRequests()
    const quantityOfRequests = response.requestIds?.length
    const message = quantityOfRequests === 1 ? 'request has been' : 'requests have been'
    dispatch(showSuccessMessage(`${quantityOfRequests} ${message} ${approvedDeclined}`))
    setRerender(rerenderDataGrid + 1)
  }
  const resetModal = () => {
    setComments('')
    setCommentsError(false)
    setShowModal(false)
  }
  const sendRequestsToApprove = (requestIds: number[]) => {
    const request: BulkSubmitRequest = {
      requestIds,
      comments,
    }
    v2MyActionsService
      .postBulkSubmitApprove(request)
      .then(response => handleSuccess(response, `approved`))
      .catch(err => {
        dispatch(showErrorMessage(`The requests have not been approved`))
      })
      .finally(resetModal)
  }

  const sendRequestsToDecline = (requestIds: number[]) => {
    const request: BulkSubmitRequest = {
      requestIds,
      comments,
    }
    v2MyActionsService
      .postBulkSubmitDecline(request)
      .then(response => handleSuccess(response, `declined`))
      .catch(err => {
        dispatch(showErrorMessage(`The requests have not been declined`))
      })
      .finally(resetModal)
  }

  const setModalData = (ids: number[], operation: OperationType) => {
    const isSingleCallOutDeclined =
      hasCallOutDeclined.current && declinedTriggeredBy.current === 'single'
    const isBulkCallOutDeclined =
      callOutDeclinedCount.current > 0 &&
      declinedTriggeredBy.current === 'bulk' &&
      operation === operationTypes.DECLINE

    const modal = modalStateRef.current
    if (isSingleCallOutDeclined || isBulkCallOutDeclined) {
      modal.message = `By declining this request, you confirm that you will review all callouts for this employee to ensure correct payments are made`
    } else {
      modal.message = `Are you sure you would like to ${operation} ${ids.length} request(s)?`
    }
    modal.operation = operation
    modal.requestIds = ids
    hasCallOutDeclined.current = false
  }

  const approveRequests = (ids: number[]) => {
    setModalData(ids, operationTypes.APPROVE)
    setShowModal(true)
  }

  const declineRequests = (ids: number[]) => {
    setModalData(ids, operationTypes.DECLINE)
    setShowModal(true)
  }

  const handleNavigation = (type: string, id: number) => {
    const baseUrl = 'myactions'
    navigate(resolveToPath(type, id), { state: { previousLocation: `/${baseUrl}` } })
  }

  const getColumnTestId = (columnName: string): string => `MyActions-Chart-${columnName}`
  const handleOnDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds)
  }, [])
  const getRowClassName = (params: GridRowClassNameParams) =>
    detailPanelExpandedRowIds.includes(params.id) ? 'expanded' : ''

  /**
   * Append panel if requestType is call out
   */
  const getCalloutSummaryPanel = useCallback(({ row }: GridRowParams) => {
    if (row.requestType === enhancementTypes.callOut) {
      return <CallOutSummary enhancementId={row.id} />
    }
    return null
  }, [])

  /**
   * Desktop View Columns
   */
  let columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Name',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => headerText(getColumnTestId(params.field), params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-name-${params.row.id}`}>
          <Link component="button" variant="body2">
            {params.row.displayName}
          </Link>
        </div>
      ),
    },

    {
      field: 'requestType',
      headerName: 'Type',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => headerText(getColumnTestId(params.field), params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-request-type-${params.row.id}`}>
          <TypeLabel
            label={getPalleteTypeByProp(params.row.requestType).label}
            type={params.row.requestType}
            testId={`type-chip-${params.row.id}`}
          />
        </div>
      ),
    },
    {
      field: 'submitDateTime',
      headerName: 'Submitted',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => headerText(getColumnTestId(params.field), params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-submitted-${params.row.id}`}>
          {getLocalDateString(params.value)}
        </div>
      ),
    },
    {
      field: 'dateFrom',
      headerName: 'Date from',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => headerText(getColumnTestId(params.field), params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-date-from-${params.row.id}`}>
          {getLocalDateString(params.value)}
        </div>
      ),
    },
    {
      field: 'dateTo',
      headerName: 'Date to',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => headerText(getColumnTestId(params.field), params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-date-to-${params.row.id}`}>
          {params.value && getLocalDateString(params.value)}
        </div>
      ),
    },
    {
      field: 'hours',
      headerName: 'Hours',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => headerText(getColumnTestId(params.field), params.colDef.headerName),
      renderCell: params => {
        let displayValue

        // Call out is currently the only request type that can genuinely have 0 hours
        if (params.row.requestType === 'Call Out') {
          displayValue = removeTrailingZeroes(params.row.paidHours)
        } else {
          displayValue = params.value === 0 ? '-' : removeTrailingZeroes(params.value)
        }

        return <div data-testid={`cell-hours-${params.row.id}`}>{displayValue}</div>
      },
    },
  ]
  const statusColumn: GridColDef[] = [
    {
      field: 'statusDescription',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => headerText(getColumnTestId(params.field), params.colDef.headerName),
      renderCell: params => (
        <div data-testid={`cell-status-${params.row.id}`}>
          <StatusLabel status={calculateStatusLabel(params.row)} />
        </div>
      ),
    },
  ]

  if (enhancementsSettingOn) {
    const leftColumns: GridColDef[] = [
      {
        field: 'bulkSubmit',
        headerName: 'bulkSubmit',
        headerClassName: 'header',
        minWidth: 45,
        maxWidth: 45,
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: params => (
          <div data-testid="header-bulk-submit">
            <Checkbox
              data-testid="header-checkbox-bulk-submit"
              checked={headerChecked}
              style={{ margin: '9px 30px 9px 0' }}
              onChange={event => {
                if (event.target.checked) {
                  setHeaderChecked(true)
                  const sortedRows = apiRef.current.getSortedRows()
                  const addToArray = (array: number[], id: number) => {
                    if (!array.includes(id)) array.push(id)
                  }

                  sortedRows.forEach(row => {
                    if (canDecline(row.statusDescription, row.requestType))
                      addToArray(bulkSumitDeclineRequestIds, row.id)
                    if (row.requestType === 'Call Out') {
                      callOutDeclinedCount.current += 1
                    }
                    if (canApprove(row.statusDescription, row.requestType))
                      addToArray(bulkSumitApproveRequestIds, row.id)
                  })
                } else {
                  setHeaderChecked(false)
                  callOutDeclinedCount.current = 0
                  setBulkSumitDeclineRequestIds([])
                  setBulkSumitApproveRequestIds([])
                }
                setRerenderCheckBox(rerenderCheckBox + 1)
              }}
              disableRipple
            />
          </div>
        ),

        renderCell: params => (
          <div data-testid={`cell-bulk-submit-${params.row.id}`}>
            {canDecline(params.row.statusDescription, params.row.requestType) ? (
              <Checkbox
                key={rerenderCheckBox}
                data-testid={`checkbox-bulk-submit-${params.row.id}`}
                checked={isChecked(params.row.id)}
                onClick={event => {
                  event.stopPropagation()
                }}
                onChange={event => {
                  event.stopPropagation()
                  updateBulkApproveDeclineArrays(
                    event.target.checked,
                    params.row.id,
                    params.row.statusDescription,
                    params.row.requestType
                  )
                  setRerenderCheckBox(rerenderCheckBox + 1)
                }}
                style={{ margin: '9px 30px 9px 0' }}
                disableRipple
              />
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: params => (
          <div data-testid={`cell-call-out-toggle-${params.row.id}`}>
            <RowExpansionToggle id={params.id} value={params.value} />
          </div>
        ),
      },
    ]
    const rightColumns: GridColDef[] = [
      {
        field: operationTypes.APPROVE,
        headerName: '',
        headerClassName: 'header',
        flex: 1,
        renderCell: params => (
          <div data-testid={`cell-approve-decline-${params.row.id}`}>
            <IconButton
              data-testid={`button-decline-${params.row.id}`}
              disabled={!canDecline(params.row.statusDescription, params.row.requestType)}
              onClick={event => {
                event.stopPropagation()
                hasCallOutDeclined.current = params.row.requestType === 'Call Out'
                declinedTriggeredBy.current = 'single'
                declineRequests([params.row.id])
              }}
            >
              <CloseIcon color="primary" />
            </IconButton>

            <IconButton
              data-testid={`button-approve-${params.row.id}`}
              disabled={!canApprove(params.row.statusDescription, params.row.requestType)}
              onClick={event => {
                event.stopPropagation()
                approveRequests([params.row.id])
              }}
            >
              <CheckIcon color="primary" />
            </IconButton>
          </div>
        ),
      },
    ]
    columns = [...leftColumns, ...columns, ...statusColumn, ...rightColumns]
  } else {
    columns = [...columns, ...statusColumn]
  }

  /**
   * Mobile View Columns
   */
  const mobileColumns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Name',
      headerClassName: 'header',
      width: 160,
      minWidth: 160,
      disableColumnMenu: true,
      renderCell: params => (
        <div data-testid={`cell-name-${params.row.id}`}>
          <Link
            component="button"
            variant="body2"
            onClick={() => handleNavigation(params.row.requestType, params.row.id)}
          >
            {params.row.displayName}
          </Link>
        </div>
      ),
    },

    {
      field: 'requestType',
      headerName: 'Type',
      headerClassName: 'header',
      width: 130,
      minWidth: 130,
      disableColumnMenu: true,
      renderCell: params => (
        <div data-testid={`cell-request-type-${params.row.id}`}>
          <TypeLabel label={params.row.requestType} type={params.row.requestType} />
        </div>
      ),
    },
    {
      field: 'submitDateTime',
      headerName: 'Submitted',
      headerClassName: 'header',
      width: 130,
      minWidth: 130,
      disableColumnMenu: true,
      type: 'date',

      renderCell: params => (
        <div data-testid={`cell-submitted-${params.row.id}`}>
          {getLocalDateString(params.value)}
        </div>
      ),
    },
    {
      field: 'dateFrom',
      headerName: 'Date from',
      headerClassName: 'header',
      width: 130,
      minWidth: 130,
      disableColumnMenu: true,
      type: 'date',
      renderCell: params => (
        <div data-testid={`cell-date-from-${params.row.id}`}>
          {getLocalDateString(params.value)}
        </div>
      ),
    },
    {
      field: 'dateTo',
      headerName: 'Date to',
      headerClassName: 'header',
      width: 130,
      minWidth: 130,
      disableColumnMenu: true,
      type: 'date',
      renderCell: params => (
        <div data-testid={`cell-date-to-${params.row.id}`}>
          {params.value && getLocalDateString(params.value)}
        </div>
      ),
    },
    {
      field: 'statusDescription',
      headerName: 'Status',
      headerClassName: 'header',
      width: 180,
      minWidth: 180,
      disableColumnMenu: true,
      renderCell: params => (
        <div data-testid={`cell-status-${params.row.id}`}>
          <StatusLabel status={calculateStatusLabel(params.row)} />
        </div>
      ),
    },
  ]

  const isManager = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  ).includes('ViewWhoAwayManager')

  const overrideGridComponents: Partial<GridSlotsComponent> | undefined = {
    NoRowsOverlay,
    LoadingOverlay,
  }
  if (enhancementsSettingOn) overrideGridComponents.Footer = CustomGridFooter

  const StyledAproveChip = styled(Chip)`
    background: ${bulkSumitApproveRequestIds.length === 0 ? '#fff' : '#fff'};
    color: ${bulkSumitApproveRequestIds.length === 0 ? '#b5b5b5' : theme.palette.primary.main};
  `

  const StyledDeclineChip = styled(Chip)`
    background: ${bulkSumitDeclineRequestIds.length === 0 ? '#b5b5b5' : '#e75e5e'};
    color: ${bulkSumitDeclineRequestIds.length === 0 ? '#fff' : '#fff'};
  `
  const handleModalConfirmation = () => {
    if (modalStateRef.current.operation === operationTypes.APPROVE)
      sendRequestsToApprove(modalStateRef.current.requestIds)
    else if (comments) sendRequestsToDecline(modalStateRef.current.requestIds)
    else setCommentsError(true)
  }

  const textFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        if (textFieldRef.current) {
          textFieldRef.current.focus()
        }
      }, 100)
    }
  }, [showModal])
  return (
    <Card>
      {enhancementsSettingOn ? (
        <>
          <Grid
            item
            xs={12}
            md={12}
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="right"
            gap={2}
          >
            <Box display="flex">
              <MyActionsFilter
                selectionLists={selectionLists}
                requestsCount={requestsArray?.length}
                disabled={isLoading}
              />
              <FilterChips />
            </Box>
            <Box sx={bulkActionsContainer}>
              <ButtonSmall
                label="Decline Selected"
                onClick={() => {
                  declinedTriggeredBy.current = 'bulk'
                  declineRequests(bulkSumitDeclineRequestIds)
                }}
                disabled={bulkSumitDeclineRequestIds.length === 0}
                variant="outlined"
                color="error"
                sx={approveButtonStyles}
                dataTestId="bulk-decline-button"
                endIcon={
                  <StyledDeclineChip
                    data-testid="bulk-decline-button-counter"
                    sx={countChipStyles}
                    label={bulkSumitDeclineRequestIds.length}
                  />
                }
              />
              <ButtonSmall
                label="Approve Selected"
                onClick={() => approveRequests(bulkSumitApproveRequestIds)}
                disabled={bulkSumitApproveRequestIds.length === 0}
                variant="outlined"
                color="primary"
                sx={declineButtonStyles}
                dataTestId="bulk-approve-button"
                endIcon={
                  <StyledAproveChip
                    data-testid="bulk-approve-button-counter"
                    sx={countChipStyles}
                    label={bulkSumitApproveRequestIds.length}
                  />
                }
              />
            </Box>
          </Grid>
          <Modal
            type="question"
            open={showModal}
            onClose={resetModal}
            onClick={() => handleModalConfirmation()}
            title="Are you sure?"
            message={modalStateRef.current.message}
            buttonLabel="Confirm"
            isDisabled={false}
            maxWidth="sm"
            dropdown={
              <Grid container>
                <Grid item xs={12} md={12} spacing={4}>
                  <Grid mt={2}>
                    <TextField
                      inputRef={textFieldRef}
                      label="Comments"
                      value={comments}
                      fullWidth
                      multiline
                      rows={4}
                      onChange={e => setComments(e.target.value)}
                      error={commentsError}
                      helperText={
                        modalStateRef.current.operation === operationTypes.APPROVE ? '' : 'Required'
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </>
      ) : (
        <>
          <Box display="flex">
            <MyActionsFilter
              selectionLists={selectionLists}
              requestsCount={requestsArray?.length}
              disabled={isLoading}
            />
            <FilterChips />
          </Box>
        </>
      )}

      {mobile ? (
        <Grid container data-testid="my-actions-mobile-view-message">
          <Grid item xs={12} mb={1}>
            <Grid item mb={2}>
              {isManager && doesDisplayInfoMessage && (
                <Alert
                  onClose={() => {
                    setDoesDisplayInfoMessage(false)
                  }}
                  severity="info"
                  message="Only Holiday requests can be viewed and actioned on mobile devices"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {enhancementsSettingOn ? (
        <Box sx={{ height: 'calc(100vh - 330px) !important' }}>
          <SytledMyActionsGrid
            key={rerenderDataGrid}
            apiRef={apiRef}
            getRowId={row => row.id}
            rows={showLoadingSpinner ? [] : filteredRequests}
            columns={mobile ? mobileColumns : columns}
            disableSelectionOnClick
            rowHeight={50}
            loading={showLoadingSpinner}
            components={overrideGridComponents}
            pinnedColumns={{ left: mobile ? ['displayName'] : [] }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'submitDateTime', sort: 'asc' }],
              },
            }}
            onRowClick={params => {
              handleNavigation(params.row.requestType, params.row.id)
            }}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleOnDetailPanelExpandedRowIdsChange}
            getDetailPanelContent={getCalloutSummaryPanel}
            getDetailPanelHeight={() => 150}
            getRowClassName={getRowClassName}
            componentsProps={{
              panel: {
                sx: {
                  '& .MuiNativeSelect-select option': {
                    font: '13px "Poppins", sans-serif',
                  },
                },
              },
              footer: {
                selectedRows: bulkSumitDeclineRequestIds.length,
                testId: 'my-actions-grid-custom-footer',
              },
            }}
            data-testid={getColumnTestId}
          />
        </Box>
      ) : (
        <SytledMyActionsGrid
          key={rerenderDataGrid}
          apiRef={apiRef}
          getRowId={row => row.id}
          rows={filteredRequests}
          columns={mobile ? mobileColumns : columns}
          disableSelectionOnClick
          pagination
          autoHeight
          rowsPerPageOptions={[10, 25, 50, 100]}
          pageSize={pageSize}
          rowHeight={50}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          loading={showLoadingSpinner}
          components={overrideGridComponents}
          pinnedColumns={{ left: mobile ? ['displayName'] : [] }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'submitDateTime', sort: 'asc' }],
            },
          }}
          onRowClick={params => {
            handleNavigation(params.row.requestType, params.row.id)
          }}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleOnDetailPanelExpandedRowIdsChange}
          getDetailPanelContent={getCalloutSummaryPanel}
          getDetailPanelHeight={() => 150}
          getRowClassName={getRowClassName}
          componentsProps={{
            panel: {
              sx: {
                '& .MuiNativeSelect-select option': {
                  font: '13px "Poppins", sans-serif',
                },
              },
            },
          }}
          data-testid={getColumnTestId}
        />
      )}
    </Card>
  )
}
