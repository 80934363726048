import { useMemo } from "react"
import { Grid } from "@mui/material"
import Alert, { alertSeverities } from "../../../shared/UI/Alert/Alert"
import { RequestOverlapsResponse } from "../../../types/requests"


interface OccurenceRequestWarningsProps {
  bankHolidaysDays: () => string | undefined
  hasBankHolidaysToDisplay: () => boolean
  overlapsResponse: RequestOverlapsResponse | undefined

}

function OccurenceRequestWarnings({
  hasBankHolidaysToDisplay,
  bankHolidaysDays,
  overlapsResponse 
}: OccurenceRequestWarningsProps) {

  const bankHolidaysInfo = useMemo((): JSX.Element => (
    <>
      {hasBankHolidaysToDisplay() && (
        <Grid item xs={12}>
          <Alert
            severity={alertSeverities.info}
            message={`There are bank holidays on: ${bankHolidaysDays()}`}
          />
        </Grid>
      )}
    </>
  ), [bankHolidaysDays, hasBankHolidaysToDisplay])

  const overlapsError = useMemo((): JSX.Element => (
    <>
      {overlapsResponse?.overlapsError && (
        <Grid item xs={12}>
          <Alert
            severity={alertSeverities.error}
            message={overlapsResponse.overlapsError}
          />
        </Grid>
      )}
    </>
  ), [overlapsResponse?.overlapsError])

  const overlapsWarning = useMemo((): JSX.Element => (
    <>
      {overlapsResponse?.overlapsWarning && (
        <Grid item xs={12}>
          <Alert
            severity={alertSeverities.warning}
            message={overlapsResponse.overlapsWarning}
          />
        </Grid>
      )}
    </>
  ), [overlapsResponse?.overlapsWarning])

  const exceedingWarning = useMemo((): JSX.Element => (
    <>
      {overlapsResponse?.exceedingWarning && (
        <Grid item xs={12}>
          <Alert
            severity={alertSeverities.warning}
            message={overlapsResponse.exceedingWarning}
          />
        </Grid>
      )}
    </>
  ), [overlapsResponse?.exceedingWarning])

  return (
    <>
      {bankHolidaysInfo}
      {overlapsResponse &&
        <>
          {overlapsError}
          {overlapsWarning}
          {exceedingWarning}
        </>
      }
    </>
  )
}

export default OccurenceRequestWarnings