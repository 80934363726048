import { AxiosError } from 'axios'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import { useAppDispatch } from '../../redux/store'
import { ApiError, BaseResponse } from '../../types/base-response'
import UserErrorMessage from '../errorFilter'

export const useDefaultErrorHandler = () => {
  const dispatch = useAppDispatch()

  const handleError = (err: AxiosError<BaseResponse>) => {
    if (err?.response?.data) {
      const baseResponse: BaseResponse = err.response.data

      baseResponse.errors.forEach((e: ApiError) => {
        dispatch(showErrorMessage(<UserErrorMessage name={e.name} description={e.description} />))
      })
    } else {
      dispatch(showErrorMessage(<UserErrorMessage name={err.name} />))
    }
  }

  return handleError
}
