import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

import {
  WallChartHeaderSegmentPosition,
  WallChartHeaderSegmentProps,
  WallChartHeaderSegmentTextProps,
} from './types'

const getBorder = (
  position: WallChartHeaderSegmentPosition,
  isToday?: boolean,
  isTomorrow?: boolean
): object => {
  const borderOpacity = isTomorrow ? '0.6' : '0.3'
  switch (position) {
    case WallChartHeaderSegmentPosition.FIRST:
      return { borderLeft: `2px solid ${isToday ? 'rgba(32, 197, 160, 0.3)' : '#CECECE'}` }
    case WallChartHeaderSegmentPosition.LAST:
      return {
        borderLeft: `1px solid ${
          isToday || isTomorrow ? `rgba(32, 197, 160, ${borderOpacity})` : '#CECECE'
        }`,
        borderRight: `2px solid ${isToday ? 'rgba(32, 197, 160, 0.3)' : '#CECECE'}`,
      }
    default:
      return {
        borderLeft: `1px solid ${
          isToday || isTomorrow ? `rgba(32, 197, 160, ${borderOpacity})` : '#CECECE'
        }`,
      }
  }
}

export const WallChartHeaderSegment = styled(Grid, {
  shouldForwardProp: prop => prop !== 'position',
})<WallChartHeaderSegmentProps>(({ position, isToday, isTomorrow }) => ({
  ...(position && getBorder(position, isToday, isTomorrow)),
  ...{ textAlign: 'center', borderBottom: '2px solid #D5EAFF' },
  ...(isToday && {
    backgroundColor: 'rgba(32, 197, 160, 0.3)',
    '&.MuiGrid-root .MuiBox-root': { color: '#000' },
  }),
})) as React.ComponentType<WallChartHeaderSegmentProps>

export const WallChartHeaderSegmentTextTop = styled(
  Box,
  {}
)<WallChartHeaderSegmentTextProps>(() => ({
  ...{
    color: '#A7A7A7',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '10px',
    paddingTop: '6px',
  },
})) as React.ComponentType<WallChartHeaderSegmentTextProps>

export const WallChartHeaderSegmentTextBottom = styled(
  Box,
  {}
)<WallChartHeaderSegmentTextProps>(() => ({
  ...{
    color: '#747474',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 'bold',
    letterSpacing: '0.26px',
  },
})) as React.ComponentType<WallChartHeaderSegmentTextProps>
