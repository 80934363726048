import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { RootStore } from '../../redux/store'
import { EnhancementGetResponse } from '../../models/enhancement'
import { TotalHoursCard } from './TotalHours/TotalHoursCard'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import Card from '../../shared/layout/Card'
import { v2MyActionsService } from '../../services/myActionsServiceV2'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import NoDataFound from '../../shared/UI/NoDataFound'
import { EmployeeDetailsResponse } from '../../types/employee'
import { hasUserAccessToRoute } from '../../utils/app-utils'
import Approval from './Approval/Approval'
import InstanceStatusBanner from '../../shared/UI/InstanceStatusBanner'
import { useDefaultErrorHandler } from '../../utils/Hooks/useDefaultErrorHandler'
import { calculateTotalHours } from '../MyRequests/utils'
import { getRequestStatusDisplayName } from '../../utils/SharedMethods/getRequestStatusDisplayName'

export function EnhancementRequest() {
  const { id: enhancementId } = useParams<{ id: string }>()
  const [enhancement, setEnhancement] = useState<EnhancementGetResponse>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalHours, setTotalHours] = useState<string>('')
  const defaultErrorHandler = useDefaultErrorHandler()
  /*
   * Start feature flag code
   * Check if user has the permissions to view this page and the feature toggle is on
   */
  const pageURL = '/myactions'
  const navigate = useNavigate()
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])

  // Check if user has the permissions to view this page and the feature toggle is on
    useEffect(() => {
        if (!userPermissions || !userSettings) {
            return
        }

        if (!hasUserAccessToRoute(pageURL, userPermissions, employeeDetails.isContractor, userSettings)) {
            navigate('/dashboard')
        }
    }, [employeeDetails.isContractor, userSettings, enhancementsSettingOn, navigate, userPermissions])
  /*
   * End feature flag code
   */

  const getEnhancementDetails = () => {
    v2MyActionsService
      .getEnhancementForManager(Number(enhancementId))
      .then((response: EnhancementGetResponse) => {
        setTotalHours(calculateTotalHours(response.days!).toFixed(2))
        setEnhancement(response)
      })
      .catch(defaultErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (enhancementId) {
      setIsLoading(true)
      getEnhancementDetails()
    }
  }, [enhancementId])
  return enhancement ? (
    <>
      <MyActionsPageHeader title={`${enhancement?.enhancementType || ''} Request`} hideNewRequest />
      <Box mb={3}>
        <InstanceStatusBanner
          showBackButton
          displayName={enhancement.employeeDetails?.displayName}
          status={getRequestStatusDisplayName(enhancement.currentStatus)}
          requestId={enhancement.id}
        />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              xl={6}
              style={{
                display: 'flex',
                flexFlow: 'column',
                flex: '1 1 auto',
              }}
            >
              <TotalHoursCard {...enhancement} totalHours={totalHours} />
            </Grid>
            <Grid
              item
              xs={12}
              xl={6}
              style={{
                display: 'flex',
                flexFlow: 'column',
                flex: '1 1 auto',
                minWidth: '300px',
              }}
            >
              <Card title="Summary" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Approval
            {...enhancement}
            setTotalHours={setTotalHours}
            onStatusChangeSuccess={getEnhancementDetails}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <NoDataFound show={!isLoading} />
      <LoadingIndicator show={isLoading} />
    </>
  )
}
