import { Grid } from '@mui/material'
import { addDays, format, isBefore } from 'date-fns'
import { useSelector } from 'react-redux'
import { RootStore } from '../../../redux/store'
import { absoluteDate } from '../../../utils/date-utils'
import { WallChartHeaderSegmentPosition } from '../WallChartHeader/types'
import { WallChartTimespans } from '../WallChartTimespanSelector/types'
import { WallChartRowSegmentCell, WallChartRowSegment } from './components'
import { WallChartBackdropProps } from './types'

export function WallChartBackdrop({
  thresholdDates,
  bankHolidays,
  scrollPosition,
  selectedTimeSpan,
}: WallChartBackdropProps) {
  const weekendDays = [0, 6]
  const headerSegmentCount = 12 / selectedTimeSpan.segments.masterSegments

  const isManager = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  ).includes('ViewWhoAwayManager')

  const getKey = (idx: number) => String(idx)

  const isDateWeekend = (idx: number) =>
    selectedTimeSpan.segments.timespan !== WallChartTimespans.DAY &&
    weekendDays.some(d => d === addDays(selectedTimeSpan.startDate, idx).getDay())

  const getThresholdLevel = (idx: number) =>
    thresholdDates?.find(
      thd => absoluteDate(thd.date) === absoluteDate(addDays(selectedTimeSpan.startDate, idx))
    )

  const isBankHoliday = (idx: number) =>
    bankHolidays?.some(
      bhd =>
        bhd.holidayDate &&
        absoluteDate(bhd.holidayDate) === absoluteDate(addDays(selectedTimeSpan.startDate, idx))
    )

  const headerPosition = (idx: number): WallChartHeaderSegmentPosition => {
    if (idx === 0) {
      return WallChartHeaderSegmentPosition.FIRST
    }
    if (idx === selectedTimeSpan.segments.masterSegments - 1) {
      return WallChartHeaderSegmentPosition.LAST
    }
    return WallChartHeaderSegmentPosition.MIDDLE
  }

  const getGridCellDims = (): { cellSpan: number; cellQty: number } => {
    switch (selectedTimeSpan.segments.timespan) {
      case 'Month':
        return { cellSpan: 6, cellQty: 2 }
      case 'Week':
        return { cellSpan: 3, cellQty: 4 }
      case 'TwoWeek':
        return { cellSpan: 6, cellQty: 2 }
      case 'Day':
      default:
        return { cellSpan: 12, cellQty: 1 }
    }
  }
  const gridWidth = getGridCellDims()

  const { calendarDetailsResponse } = useSelector((state: RootStore) => state.appSettings)

  const notABookableDate = (idx: number) => {
    if (!calendarDetailsResponse) {
      return
    }

    const lastBookableDate = new Date(
      calendarDetailsResponse.calendarDetails[
        calendarDetailsResponse.calendarDetails.length - 1
      ].endDate
    )
    const firstBookableDate = new Date(
      format(new Date(calendarDetailsResponse.calendarDetails[0].startDate), 'yyyy-MM-dd')
    )

    if (
      selectedTimeSpan.startDate.getMonth() !==
      (lastBookableDate.getMonth() || firstBookableDate.getMonth())
    ) {
      return false
    }

    const currDate = new Date(format(addDays(selectedTimeSpan.startDate, idx), 'yyyy-MM-dd'))

    if (isBefore(currDate, firstBookableDate)) {
      return true
    }
    if (isBefore(lastBookableDate, currDate)) {
      return true
    }

    return false
  }

  return (
    <Grid container position="absolute" top={scrollPosition} pl="100px" width="100%" height="100%" data-testid="wall-chart-backdrop">
      <Grid item xs={12}>
        <Grid container position="relative" height="100%">
          {new Array(selectedTimeSpan.segments.masterSegments).fill(0).map((_, idx) => (
            <WallChartRowSegment
              container
              key={getKey(idx)}
              xs={headerSegmentCount}
              isWeekend={isDateWeekend(idx) || notABookableDate(idx)}
              position={headerPosition(idx)}
              thresholdLevel={getThresholdLevel(idx)?.threshold}
              bankHoliday={isBankHoliday(idx)}
              isManager={isManager}
              data-testid="wall-chart-row-segment"
            >
              {new Array(gridWidth.cellQty).fill(0).map((__, iidx) => (
                <WallChartRowSegmentCell
                  item
                  key={getKey(iidx)}
                  xs={gridWidth.cellSpan}
                  isManager={isManager}
                />
              ))}
            </WallChartRowSegment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
