/* tslint:disable */
/* eslint-disable */
/**
 * Enhancement API
 * Enhancements API
 *
 * OpenAPI spec version: 1.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SubmitStatus {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  APPROVED = 'APPROVED',
  SUBMITTED = 'SUBMITTED',
  AMENDED = 'AMENDED',
  MOVED = 'MOVED',
}
