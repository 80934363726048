import React from 'react'
import { Box } from '@mui/material'
import { dateOrdinal } from '../../../utils/app-utils'
import { getLongMonthString } from '../../../utils/date-utils'

interface PayPeriodContentProps {
  payPeriod: number
  dateRange: {
    start: string
    end: string
  }
}
const contentStyles = {
  marginRight: '24px',
  fontSize: '14px',
  fontWeight: '700',
  letterSpacing: '-0.56px',
  color: 'inherit',
}
/**
 * Converts given day and date to string date with superscrited ordinal
 * e.g  13, new Date('13-12-2024') => 13<sup>th</sup> December
 */
const toOrdinalDateString = (date: Date) => {
  const day = date.getDate()
  return `${day}${dateOrdinal(day)}`
}

export function PayPeriodContent({ dateRange, payPeriod }: PayPeriodContentProps) {
  const { start, end } = dateRange
  const startDate = new Date(start)
  const endDate = new Date(end)

  // Get the months from dates
  const startMonth = getLongMonthString(startDate)
  const endMonth = getLongMonthString(endDate)

  return (
    <span data-testid="pay-period-content">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ marginRight: '8px', fontWeight: 'bold' }}>
          {`Pay Period ${payPeriod}:`}
        </Box>
        <Box>
          {`${toOrdinalDateString(startDate)} ${startMonth} - ${toOrdinalDateString(endDate)} ${endMonth}`}
        </Box>
      </Box>
    </span>
  )
}
