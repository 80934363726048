import { Alert as MuiAlert } from '@mui/material'

export type AlertSeverity = 'success' | 'warning' | 'info' | 'error'

interface AlertSeverities {
  success: AlertSeverity,
  warning: AlertSeverity,
  info: AlertSeverity,
  error: AlertSeverity,
}

export const alertSeverities: AlertSeverities = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error',
}

const ALERT_SEVERITIES: string[] = [
  alertSeverities.success as string,
  alertSeverities.warning as string,
  alertSeverities.info as string,
  alertSeverities.error as string,
]

const valueOrDefaultAlertSeverity = (value: string): AlertSeverity =>
  ALERT_SEVERITIES.includes(value)
    ? value as AlertSeverity
    : alertSeverities.error

type Props = {
  severity: AlertSeverity
  message: string
  testId?: string
  onClose?: () => void
}

function Alert({ severity, message, onClose }: Props) {
  return <MuiAlert onClose={onClose} severity={valueOrDefaultAlertSeverity(severity)}>{message}</MuiAlert>
}

export default Alert
