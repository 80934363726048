import { useCallback, useEffect, useState } from 'react'
import { Grid, IconButton, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { useSelector } from 'react-redux'
import { Employee, SelectOption, Team } from '../../../services/dashboardService'
import AutocompleteList from '../../../shared/UI/AutocompleteList'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import { IOSStyleSwitch } from '../../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import Paragraph from '../../../shared/UI/Paragraph'
import { directReportsStyles } from './Styles'
import { MyActionsFilterProps, MyActionsFilterState } from './Types'
import DateRangePicker from '../../../shared/UI/DateRangePicker'
import { RootStore, useAppDispatch } from '../../../redux/store'
import DropdownMenu from '../../../shared/UI/DropdownMenu'
import {
  resetFilters,
  setFilterStates,
} from '../../../redux/reducers/myActionsReducer'

const testId = 'my-actions-filter'
function FilterPanel({
  selectionLists,
  handleClose,
}: MyActionsFilterProps & { handleClose: () => void }) {
  const { filterStates } = useSelector((state: RootStore) => state.myActions)
  const dispatch = useAppDispatch()
  const { allEmployees } = useSelector((state: RootStore) => state.appSettings)
  /**
   * State is used so that user changes are reflected in the GUI
   * requestFilterSettings are updated with this call but does not tigger a rerender.
   * dispatch is called when filters are applied
   */
  const [filterState, setFilterState] = useState<MyActionsFilterState>(filterStates)
  const [teamOptions, setTeamOptions] = useState<Team[]>([])
  const [employeeOptions, setEmployeeOptions] = useState<Employee[]>([])

  const [teamSelectionDisabled, setTeamSelectionDisabled] = useState<boolean>(
    selectionLists.current.departments.length > 1
  )

  useEffect(() => {
    const { directReportees, department, team } = filterState.selectionState
    setEmployeeOptions(
      allEmployees.filter(emp => {
        let res = true
        if (directReportees && !emp.directReportee) res = false
        if (!directReportees && !emp.inHierarchy) res = false
        if (department && department.displayValue !== 'All' && emp.departmentId !== department.value) res = false
        if (team && emp.teamId !== team.teamId) res = false
        return res
      })
    )
  }, [allEmployees, filterState.selectionState])

  const initialiseTeamOptions = useEffect(() => {
    const { departments, teams } = selectionLists.current

    const options = filterState.selectionState.department
      ? teams[filterState.selectionState.department.value]
      : teams[departments[0].value]
    if (options) {
      setTeamSelectionDisabled(options.length === 0)
      setTeamOptions(options)
    }
  }, [])

  const updateTeamOptions = useCallback((departmentOption?: SelectOption) => {
    const { teams } = selectionLists.current
    if (departmentOption && departmentOption?.value !== 0) {
      const options = teams[departmentOption.value]
      setTeamSelectionDisabled(options.length === 0)
      setTeamOptions(options)
    }
  }, [])

  /**
   * Filter values are reset and the GUI refresh triggerd in the parent page
   */
  const resetToDefaultSettings = useCallback(() => {
    dispatch(resetFilters())
    handleClose()
  }, [])

  const handleCheckBoxChange = useCallback((type: string) => {
    setFilterState(latestFilterState => ({
      ...latestFilterState,
      checkboxSettings: latestFilterState.checkboxSettings.map(setting => ({
        ...setting,
        checked: setting.type === type ? !setting.checked : setting.checked,
      })),
    }))
  }, [])

  const handleStatusSelection = useCallback((value: number) => {
    setFilterState(latestFilterState => ({
      ...latestFilterState,
      requestParams: { ...latestFilterState.requestParams, requestStatusId: value },
      selectionState: { ...latestFilterState.selectionState, status: value },
    }))
  }, [])

  const handleEmployeeSelection = useCallback((employees: Employee[] | null) => {
    setFilterState(latestFilterState => ({
      ...latestFilterState,
      requestParams: {
        ...latestFilterState.requestParams,
        employeeIds: employees?.map(emp => emp.employeeId),
      },
      selectionState: { ...latestFilterState.selectionState, employees },
    }))
  }, [])

  const handleTeamSelection = useCallback(
    (team: Team | null) => {
      const employees = team
        ? filterState.selectionState.employees?.filter(emp => {
            if (team && team.teamId !== emp.teamId) return false
            return true
          })
        : filterState.selectionState.employees
      const employeeIds = employees?.map(emp => emp.employeeId)

      setFilterState(latestFilterState => ({
        ...latestFilterState,
        requestParams: {
          ...latestFilterState.requestParams,
          departmentTeamId: team ? team.teamId : undefined,
          employeeIds,
        },
        selectionState: {
          ...latestFilterState.selectionState,
          team,
          employees: employees || [],
        },
      }))
    },
    [filterState.selectionState.employees]
  )

  const handleDepartmentSelection = useCallback(
    (departmentOption: SelectOption | null) => {
      /*
       * if departmentOption is null or 0:
       * disable team selection
       * remove current team selection
       */
      handleTeamSelection(null)
      if (!departmentOption || departmentOption.value === 0) {
        setTeamSelectionDisabled(true)
      } else {
        updateTeamOptions(departmentOption)
      }
      const employees = departmentOption
        ? filterState.selectionState.employees?.filter(emp => {
            if (departmentOption && departmentOption.value !== emp.departmentId) return false
            return true
          })
        : filterState.selectionState.employees
      const employeeIds = employees?.map(emp => emp.employeeId)

      setFilterState(latestFilterState => ({
        ...latestFilterState,
        requestParams: {
          ...latestFilterState.requestParams,
          departmentId: departmentOption ? departmentOption.value : undefined,
          employeeIds,
        },
        selectionState: {
          ...latestFilterState.selectionState,
          department: departmentOption,
          employees: employees || [],
        },
      }))
    },
    [filterState.selectionState.employees, handleTeamSelection, updateTeamOptions]
  )

  const handleDateRangeSelection = useCallback((dateRange: DateRange<Date>) => {
    const [dateFrom, dateTo] = dateRange
    if (dateTo && dateFrom) {
      setFilterState(latestFilterState => ({
        ...latestFilterState,
        requestParams: { ...latestFilterState.requestParams, dateFrom, dateTo },
        selectionState: { ...latestFilterState.selectionState, dateRange },
      }))
    }
  }, [])

  const handleDirectReporteesToggle = useCallback(
    (directReportees: boolean) => {
      const employees = directReportees
        ? filterState.selectionState.employees?.filter(emp => {
            if (directReportees && !emp.directReportee) return false
            return true
          })
        : filterState.selectionState.employees
      const employeeIds = employees?.map(emp => emp.employeeId)

      setFilterState(latestFilterState => ({
        ...latestFilterState,
        requestParams: {
          ...latestFilterState.requestParams,
          directReportees,
          employeeIds,
        },
        selectionState: {
          ...latestFilterState.selectionState,
          directReportees,
          employees: employees || [],
        },
      }))
    },
    [filterState.selectionState.employees]
  )

  const submitFilterParams = () => {
    dispatch(setFilterStates(filterState))
    handleClose()
  }

  return (
    <Grid container data-testid="filter-panel">
      {/* Left side filters */}
      <Grid item xs={12} md={4} p={3} pr={1}>
        <Grid item display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Paragraph weight="bold" style={{ marginLeft: '-8px' }}>
            Type Filters
          </Paragraph>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={handleClose}
            sx={{ p: 0, display: { sm: 'none' } }}
          >
            <CloseOutlinedIcon fontSize="inherit" color="primary" />
          </IconButton>
        </Grid>
        <FormGroup>
          <Grid container>
            {filterState.checkboxSettings
              .filter(setting => setting?.count && setting?.count > 0)
              .map(({ displayName, type, checked, count }) => (
                <Grid item xs={4} md={12} gap={1}>
                  <FormControlLabel
                    control={<Checkbox checked={checked} defaultChecked={checked} sx={{ mr: 1 }} />}
                    label={`${displayName} (${count})`}
                    sx={{ mb: 1 }}
                    key={type}
                    data-testid={type}
                    onChange={() => handleCheckBoxChange(type)}
                  />
                </Grid>
              ))}
          </Grid>
        </FormGroup>
      </Grid>
      {/* Right side filters */}
      {!filterState.requestParams ? (
        <></>
      ) : (
        <Grid item xs={12} md={8} sx={{ backgroundColor: '#fafafa', p: 3 }}>
          <Paragraph weight="bold">Filters</Paragraph>
          <Grid item xs={12} mt={3}>
            <FormControlLabel
              control={
                <IOSStyleSwitch defaultChecked={filterState.requestParams.directReportees} />
              }
              label="Direct Reports"
              labelPlacement="start"
              onClick={() =>
                handleDirectReporteesToggle(!filterState.requestParams.directReportees)
              }
              data-testid="direct-reports"
              sx={directReportsStyles}
            />
          </Grid>
          <Grid
            container
            display="flex"
            direction="column"
            spacing={4}
            data-testid="my-actions-filter"
          >
            <Grid item xs={12}>
              <Grid container spacing={3} pt={2}>
                <Grid item xs={12}>
                  <AutocompleteList
                    id="department"
                    label="Department"
                    data={selectionLists.current.departments}
                    textField="displayValue"
                    value={filterState.selectionState.department}
                    onChange={(_e, department: SelectOption) =>
                      handleDepartmentSelection(department)
                    }
                    dataTestId={`${testId}-department-dropdown`}
                    disabled={selectionLists.current.departments.length === 1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteList
                    id="team"
                    label="Team"
                    data={teamOptions}
                    textField="teamName"
                    value={filterState.selectionState.team}
                    onChange={(_e, team: Team) => handleTeamSelection(team)}
                    dataTestId={`${testId}-team-dropdown`}
                    disabled={teamSelectionDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteList
                    multiple
                    id="employee"
                    label="Employee"
                    data={employeeOptions}
                    textField="employeeName"
                    value={filterState.selectionState.employees || []}
                    onChange={(_e, employee: Employee[]) => handleEmployeeSelection(employee)}
                    dataTestId={`${testId}-employee-dropdown`}
                    limitTags={1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateRangePicker
                    arrange="Stack"
                    startText="Date from"
                    endText="Date to"
                    onChange={(date: DateRange<Date>) => handleDateRangeSelection(date)}
                    value={filterState.selectionState.dateRange || [null, null]}
                    dataTestId={`${testId}-date-range`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownMenu
                    label="Status"
                    id="searchStatus"
                    data={selectionLists.current.statuses}
                    textField="displayValue"
                    valueField="value"
                    value={filterState.selectionState.status}
                    onChange={e => handleStatusSelection(Number(e.target.value))}
                    dataTestId="MyActions-Filters-StatusDDL"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  mt={-2}
                >
                  <Button
                    size="small"
                    onClick={resetToDefaultSettings}
                    startIcon={<RestartAltIcon />}
                    sx={{ mr: 4 }}
                  >
                    Reset all
                  </Button>
                  <ButtonSmall
                    label="Apply"
                    sx={{ minWidth: '90px', fontSize: '11px' }}
                    onClick={e => submitFilterParams()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default FilterPanel
